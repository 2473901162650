import React from "react";
import { graphql } from "gatsby";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Layout from "ps-components/Layout/Layout.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
const axios = require(`axios`);
import { Helmet } from "react-helmet";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import customStaticPageStyle from "ps-assets/jss/customStaticPageStyle.jsx";
import { browserDocument } from "util/localStorageHelper";
var apiBaseUrl = process.env.API_URL;

let url = process.env.BASE_URL;
class ListAgents extends React.Component {
  state = {
    Agents: [],
    currentPage: 1,
    totalRecord: 25,
    searchValue: ``,
    pages: []
  };

  totalCount = 0;
  handleChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  };

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableSelectCell: {
          root: {
            display: `none`
          }
        }
      }
    });

  getAgents = async (currentPage, totalRecord) => {
    var where = ``;
    var page = currentPage || 1;
    var record = totalRecord || 25;
    if (typeof currentPage === `object`) page = 1;
    if (this.state.searchValue !== ``) {
      where = `&q=` + this.state.searchValue;
    }

    var url =
      `${apiBaseUrl}form/agentList?pageNo=` +
      page +
      `&totalRecord=` +
      record +
      where;
    let response = await axios.get(url);
    var tableRows = response.data.map.agentList;
    this.totalCount = response.data.map.totalRecords;
    this.setState({
      Agents: tableRows,
      currentPage: page,
      totalRecord: record
    });
  };

  componentDidMount() {
    browserDocument.addEventListener(`contextmenu`, event => event.preventDefault());
    // browserDocument
    //   .getElementById(`___gatsby`)
    //   .setAttribute(`style`, `user-select:none`);
    this.getAgents();
  }

  // componentWillUnmount() {
  //   browserDocument.getElementById(`___gatsby`).removeAttribute(`style`);
  // }

  render() {
    const { classes, data } = this.props;
    var Images = data.allStrapiImages.edges;
    var clrImage = Images[1];
    var columns = [
      {
        name: `id`,
        label: `SL.No`,
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: `agent_code`,
        label: `Agent Code`,
        options: {
          filter: false,
          sort: false
        }
      },
      {
        name: `agent_name`,
        label: `Contact Person Name`,
        options: {
          filter: false,
          sort: false
        }
      },
      {
        name: `status`,
        label: `Status`,
        options: {
          filter: false,
          sort: false
        }
      }
    ];
    var options = {
      selectableRows: false,
      sort: false,
      filter: false,
      search: false,
      download: false,
      print: false,
      viewColumns: false,
      serverSide: true,
      responsive: `scroll`,
      count: this.totalCount,
      rowsPerPage: 25,
      rowsPerPageOptions: [10, 20, 25],
      onTableChange: (action, tableState) => {
        // a developer could react to change on an action basis or
        // examine the state as a whole and do whatever they want

        switch (action) {
          case `changePage`:
            this.getAgents(tableState.page + 1, this.state.totalRecord);
            break;
          case `changeRowsPerPage`:
            this.getAgents(this.state.currentPage, tableState.rowsPerPage);
            break;
        }
      }
    };
    return (
      <Layout
        image2={
          clrImage &&
          clrImage.node &&
          clrImage.node.content &&
          clrImage.node.content.childImageSharp.fluid
        }
        small={true}
        title={``}
        summary={``}
      >
        <Helmet key="helmetTags">
          <title>Agents List | StarHealth.in</title>
        </Helmet>
        {process.env.ENABLE_HELMET == `true` && (
          <Helmet key="helmetTags">
            <link rel="canonical" href={url + `agent-list`} />
            <meta name={`title`} content="Agents List | StarHealth.in" />
            <meta
              name={`description`}
              content="Star Health Insurance Agents Listing"
            />
            <meta property="og:title" content="Agents List | StarHealth.in" />
            <meta property="og:description" content="Star Health Insurance Agents Listing" />
            <meta property="og:url" content={url + `agent-list`}/>
            <meta name="Agents List | StarHealth.in" />
            <meta name="twitter:description" content="Star Health Insurance Agents Listing" />
            <meta property="twitter:url" content={url + `agent-list`}/>
          </Helmet>
        )}
        <div
          className={classNames(classes.main, classes.mainRaised)}
          style={{ marginBottom: 100, padding: `70px 0px` }}
        >
          <div className={classes.section}>
            <div className={classes.container}>
              <GridContainer>
                <GridItem
                  md={8}
                  sm={8}
                  className={classNames(
                    classes.mrAuto,
                    classes.mlAuto,
                    classes.textCenter
                  )}
                ></GridItem>
                {` `}
                {this.state.Posts}
                <GridItem
                  xs={12}
                  sm={6}
                  md={6}
                  className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                  <Card className={classes.card} style={{ zIndex: `9999` }}>
                    <CardBody
                      formHorizontal
                      style={{ paddingTop: `10px`, paddingBottom: `0px` }}
                    >
                      <GridContainer>
                        <GridItem xs={12} sm={8} md={8}>
                          <CustomInput
                            labelText="Search by agent code or name"
                            id="searchValue"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              required: true
                            }}
                            onChange={this.handleChange}
                            value={this.state.name}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                          <Button
                            block
                            color="primary"
                            style={{ marginTop: 20 }}
                            onClick={this.getAgents}
                          >
                            Search
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={10}
                  md={10}
                  className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                  <div
                    style={{
                      "-moz-user-select": `none`,
                      "-webkit-user-select": `none`,
                      "-ms-user-select": `none`,
                      "user-select": `none`,
                      "-o-user-select": `none`
                    }}
                    unselectable="on"
                    onselectstart="return false;"
                    onMouseDown="return false;"
                  >
                    <MuiThemeProvider theme={this.getMuiTheme()}>
                      <MUIDataTable
                        key={this.totalCount}
                        data={this.state.Agents}
                        columns={columns}
                        options={options}
                      />
                    </MuiThemeProvider>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
ListAgents.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};
export default withStyles(customStaticPageStyle)(ListAgents);

export const ListAgentsQuery = graphql`
  query ListAgents {
    allStrapiImages(
      filter: { section: { category: { eq: "list_of_agents" } } }
    ) {
      edges {
        node {
          id
          sequence
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
